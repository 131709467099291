// 引入axios方法
import { fun_PostAction } from "@/api/index";
// 引入加密方法
import "../../../utils/security.js";
export default {
  /**
   * 组件默认导出属性
   * @author 滕威
   */
  // 初始化数据
  data() {
    return {
      // 图片路径对象
      codeImgSrc: null,
      key: null, // 加密key
      // 表单对象
      request: {
        exponent: null, // 加密exponent参数
        modulus: null, // 加密modulus参数
        username: null, // 用户名
        password: null, // 密码
        title: null, // 验证码
        t: null
      },
      form: {
        username: null, // 用户名
        password: null, // 密码
        title: null // 验证码
      },
      typeShow: true
    };
  },

  // 模板编译完成
  mounted() {
    this.request.t = new Date().getTime();
    // 调用获取参数方法
    this.fun_GetRSAKey();
    // 调用验证码方法
    this.fun_GetCode();
  },
  // 自定义事件
  methods: {
    /**
     * 获取验证码接口
     * @author 滕威
     */
    fun_GetCode() {
      this.codeImgSrc = `/dbgjs-server/mime?t=${this.request.t}`;
    },
    /**
     * 获取参数接口
     * @author 滕威
     */
    fun_GetRSAKey() {
      // 调用获取加密参数接口
      fun_PostAction("/getRSAKey").then(res => {
        // 解构res.data
        const { result, data } = res.data;
        // 判断请求是否成功
        if (result) {
          // 获取exponent
          this.request.exponent = data.exponent;
          // 获取modulus
          this.request.modulus = data.modulus;
        }
      });
    },
    /**
     * 登录
     * @author 滕威
     */
    fun_SubmitForm() {
      // 校验
      this.$refs.form.validate(valid => {
        if (valid) {
          // 获取加密key
          this.key = window.RSAUtils.getKeyPair(
            this.request.exponent,
            "",
            this.request.modulus
          );
          // 遍历加密用户名、密码、验证码
          for (let e in this.form) {
            this.request[e] = window.RSAUtils.encryptedString(
              this.key,
              this.form[e]
            );
          }
          // 调用登录接口
          fun_PostAction("/login", this.request).then(res => {
            const { result, data } = res.data;
            // 回调
            if (result) {
              // 登录成功添加token到vuex
              this.$store.commit("SET_TOKEN", data);
              // 跳往首页
              this.$router.push({
                path: "/openHall"
              });
            } else {
              // 登录失败给出提示
              this.$message.error(res.data.msg);
              // 刷新验证码
              this.fun_GetCode();
              // 清空验证码输入框
              this.form.title = null;
            }
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 切换登录方式
     * @author 滕威
     */
    fun_ChangeType() {
      this.typeShow = !this.typeShow;
    }
  }
};
